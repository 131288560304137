<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="action-box">
				<el-button @click="expandAll" size="small" icon="el-icon-copy-document" plain>展开全部 </el-button>
				<el-button @click="closeAll" size="small" icon="el-icon-close" plain>关闭全部 </el-button>
				<el-button @click="addNode" size="small" icon="el-icon-plus" plain v-if="$hasAccess('system.node/add')">添加节点 </el-button>
				<el-button @click="refreshTable" size="small" icon="el-icon-refresh-right" plain>刷新 </el-button>
			</div>
		</div>
		<div class="table" :style="tableStyle">
			<vxe-table ref="xTable" show-overflow :loading="isLoading" :data="nodeList" :tree-config="{ children: 'child', expandAll: true }"
				:edit-config="{trigger: 'click', mode: 'cell', showIcon:false, showStatus:true}" height="100%" border highlight-hover-row>
				<vxe-table-column field="title" title="标题"  tree-node />
				<vxe-table-column field="name" title="命名" />
				<vxe-table-column field="status" title="状态" align="center">
					<template v-slot="{ row }">
						<div v-if="row.status == 1" class="normal">
							<span class="circle"></span>
							<span>正常</span>
						</div>
						<div v-else class="hide">
							<span class="circle"></span>
							<span>隐藏</span>
						</div>
					</template>
				</vxe-table-column>
				<vxe-table-column field="type" title="类型" align="center">
					<template v-slot="{ row }">
						<div v-if="row.type == 'action'">
							<i class="el-icon-thumb" style="margin-right: 5px"></i>操作
						</div>
						<div v-else>
							<i class="el-icon-s-operation" style="margin-right: 5px"></i>菜单
						</div>
					</template>
				</vxe-table-column>
				<vxe-table-column title="操作" align="center">
					<template v-slot="{ row }">
						<el-button size="small" icon="el-icon-edit" plain @click="editNode(row)" v-if="$hasAccess('system.node/edit')">编辑 </el-button>
						<el-button size="small" icon="el-icon-delete" plain @click="deleteNode(row)" v-if="$hasAccess('system.node/delete')">删除 </el-button>
					</template>
				</vxe-table-column>
			</vxe-table>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="dialogShow" width="600px">
			<el-form :model="dialogFormData" :rules="rules" ref="dialogForm" label-width="80px" class="form">
				<el-form-item label="类型">
					<el-radio-group v-model="dialogFormData.type">
						<el-radio label="menu">菜单</el-radio>
						<el-radio label="action">操作</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="父级" prop="pid">
					<el-cascader :options="cascaderList" :props="cascaderProps" clearable size="small" v-model="dialogFormData.pid" style="width: 300px">
					</el-cascader>
				</el-form-item>
				<el-form-item label="规则" prop="name">
					<el-input v-model="dialogFormData.name" placeholder="请输入规则" size="small" style="width: 80%"></el-input>
				</el-form-item>
				<el-form-item label="命名" prop="title">
					<el-input v-model="dialogFormData.title" placeholder="请输入命名" size="small" style="width: 80%"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="dialogFormData.remark" placeholder="请输入备注" size="small" type="textarea" style="width: 80%"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="handleConfirm('dialogForm')">保 存 </el-button>
				<el-button size="small" plain @click="closeDialog" icon="el-icon-close">取 消 </el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import Tables from '@/components/tables'
	import {
		formatTime,
		clearChild
	} from '@/utils/util'
	export default {
		name: 'system-node-list',
		components: {
			Tables
		},
		data() {
			return {
				tableName: '节点管理',
				nodeList: [],
				isLoading: false,
				tableStyle: '',
				dialogTitle: '',
				dialogShow: false,
				dialogFormData: {
					type: 'menu',
					name: '',
					title: '',
					remark: '',
					icon: '',
					weight: 0
				},
				rules: {
					pid: [{
						required: true,
						message: '请选择父级！',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入规则！',
						trigger: 'blur'
					}],
					title: [{
						required: true,
						message: '请输入命名！',
						trigger: 'blur'
					}]
				},
				cascaderProps: {
					checkStrictly: true,
					value: 'node_id',
					label: 'title',
					children: 'child',
					emitPath: false
				},
				cascaderList: []
			}
		},
		mounted() {
			this.tableStyle = `height: calc( 100% - ${this.$refs.toolbar.offsetHeight}px );`
			this.refreshTable()
		},
		methods: {
			// 重置表单数据
			resetFormData() {
				this.dialogFormData = {
					type: 'menu',
					name: '',
					title: '',
					remark: '',
					icon: '',
					weight: 0
				}
				if (this.$refs.dialogForm) {
					this.$refs.dialogForm.resetFields()
				}
			},
			expandAll() {
				this.$refs.xTable.setAllTreeExpansion(true)
			},
			closeAll() {
				this.$refs.xTable.clearTreeExpand()
			},
			addNode() {
				this.resetFormData()
				this.dialogTitle = '添加'
				this.dialogShow = true
				this.cascaderList = [{
					node_id: '0',
					title: '总系统',
					child: this.nodeList
				}]
			},
			editNode(row) {
				this.resetFormData()
				const params = {
					token: this.$store.state.user.token,
					node_id: row.node_id
				}
				// 获取用户详情
				this.$api.System.GetNodeDetail(params).then(res => {
					this.dialogTitle = '编辑'
					this.dialogShow = true
					this.cascaderList = [{
						node_id: '0',
						title: '总系统',
						child: this.nodeList
					}]
					this.dialogFormData = {
						type: row.type,
						name: row.name,
						title: row.title,
						remark: '',
						icon: row.icon,
						weight: row.weight,
						node_id: row.node_id,
						pid: row.pid
					}
					if (row.pid === 0) {
						this.dialogFormData.pid = '0'
					}
				})
			},
			refreshTable() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 500
				}
				this.isLoading = true
				this.$api.System.GetNodeList(params).then(res => {
					this.nodeList = clearChild(res)
					this.isLoading = false
					setTimeout(() => {
						this.expandAll()
					}, 100)
				}).catch(err => {
					this.isLoading = false
				})
			},
			closeDialog() {
				this.dialogShow = false
			},
			handleConfirm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let form = this.dialogFormData
						form.pid = parseInt(form.pid)
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(form)
						}
						if (this.dialogTitle === '添加') {
							this.$api.System.AddNode(params).then(res => {
								this.$notify({
									title: '成功',
									message: '添加成功',
									type: 'success'
								})
								this.refreshTable()
								this.dialogShow = false
							})
						} else {
							this.$api.System.EditNode(params).then(res => {
								this.$notify({
									title: '成功',
									message: '修改成功',
									type: 'success'
								})
								this.refreshTable()
								this.dialogShow = false
							})
						}
					}
				})
			},
			deleteNode(row) {
				this.$confirm('确定要删除该节点吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const params = {
						token: this.$store.state.user.token,
						node_id: row.node_id
					}
					this.$api.System.DeleteNode(params).then(res => {
						this.$notify({
							title: '成功',
							message: '删除成功',
							type: 'success'
						})
						this.refreshTable()
					})
				})
			}
		}
	}
</script>
<style>
	.toolbar .el-form-item {
		margin-bottom: 0 !important;
	}

	.vxe-required-icon {
		display: none !important;
	}

	.vxe-table--loading {
		background-color: hsla(0, 0%, 100%, 0.9);
	}

	/*.vxe-table--loading .vxe-table--spinner:after, .vxe-table--loading .vxe-table--spinner:before {*/
	/*background-color: #008E4D;*/
	/*}*/
	/*.vxe-table--loading .vxe-table--spinner:after, .vxe-table--loading .vxe-table--spinner:after {*/
	/*background-color: #008E4D;*/
	/*}*/
</style>
<style lang="scss" scoped>
	.normal {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #008e4d;

		.circle {
			width: 10px;
			height: 10px;
			background: #008e4d;
			border-radius: 100%;
			margin-right: 10px;
		}
	}

	.hide {
		display: flex;
		align-items: center;
		justify-content: center;
		color: gray;

		.circle {
			width: 10px;
			height: 10px;
			background: gray;
			border-radius: 100%;
			margin-right: 10px;
		}
	}

	.title {
		margin-bottom: 10px;
	}

	.toolbar {
		display: flex;
		justify-content: flex-end;
		position: relative;
		min-height: 40px;

		.search-box {
			position: relative;
			z-index: 10;
			flex-grow: 1;
		}

		.action-box {
			line-height: 40px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			z-index: 9;
		}
	}

	.table {
		margin: 10px 0;
	}

	.pagination {
		display: flex;
		justify-content: flex-end;
	}

	.remark {
		margin-top: 10px;
	}
</style>
